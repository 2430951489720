import clone from "lodash/cloneDeep"
import forEach from "lodash/forEach"
import map from "lodash/map"

import Vue from 'vue'

export default {
  getFields: function (fields, values, format) {
    return map(fields, field => {
      const config = clone(field)

      let value = values[field.label]
      if (! value) {
        value = field.default
      }

      if (config.inputType === 'number' || config.inputType === 'select') {
        value = parseFloat(value)
      }

      if (config.inputType === 'exerciseTypes') {
        config.values = format.exercises.map(et => ({
          id: et.exerciseTypeId,
          name: Vue.i18n.t('exercise.type.' + et.exerciseTypeId),
        }))
        if (value) {
          value = config.values.filter(et => value.includes(et.id))
        } else {
          value = []
        }
      }

      return { config, value }
    })
  },

  getValues: function(customFields) {
    const options = {}
    forEach(customFields, option => {
      const label = option.config.label
      if (option.value) {
        let value = option.value
        if (option.config.inputType === 'exerciseTypes') {
          console.log('map exerciseTypes')
          value = option.value.map(i => i.id)
        }
        options[label] = value
      } else if (option.config.inputType === 'copy') {
        const sourceOption = customFields.find(o => o.config.label === option.config.source)
        if (sourceOption?.value) {
          options[label] = sourceOption.value
        }
      }
    })

    return options
  },
}
