'use strict';

import map from "lodash/map";

export default {
  idNamePairs: function(options, prefix = null) {
    return map(options, o => {
      let name = o;
      if (prefix) {
        name = prefix + o;
      }
      return {id: o, name: name};
    });
  }
}
