<template>
  <div>
    <div class="o-layout">
      <div class="o-layout__item u-3-of-8">
        <div class="c-input">
          <label class="c-input__label">{{$t('label')}}</label>
        </div>
      </div>
      <div class="o-layout__item u-3-of-8">
        <div class="c-input">
          <label class="c-input__label">{{$t('value')}}</label>
        </div>
      </div>
    </div>

    <div class="o-layout c-list-awards-row" v-for="(award, award_i) in list" :key="award_i">
      <div class="o-layout__item u-3-of-8">
        <sceTextField :name="namePrefix + '_label_'+award_i" v-model="award.label"
                        v-validate data-rules="required|numeric|min_value:0|max_value:1000"
                        :class="{'is-invalid': errors.has(namePrefix + '_label_'+award_i)}" />
      </div>

      <div class="o-layout__item u-3-of-8">
        <sceNumberField :name="namePrefix + '_value_'+award_i" v-model.number="award.value"
                        min="0" max="9999" step="0.001"
                        v-validate data-rules="required|numeric|min_value:0|max_value:1000"
                        :class="{'is-invalid': errors.has(namePrefix + '_value_'+award_i)}" />
      </div>
      <div class="c-session-block__remove-button">
        <sceButton icon="svg-symbols.svg#close" class="c-button--ghost c-button--small"
                   v-on:click="removeValue(award_i)"/>
      </div>
    </div>
    <div class="o-layout c-list-awards-row">
      <div class="o-layout__item u-3-of-8">
        <sceTextField :name="namePrefix + '_spare_label'" v-model="spareLabel" />
      </div>
      <div class="o-layout__item u-3-of-8">
        <sceNumberField :name="namePrefix + '_spare_value'" v-model.number="spareValue"
                        min="0" max="9999" step="0.001" />
      </div>
      <div class="c-session-block__remove-button">
        <sceButton icon="svg-symbols.svg#add" class="c-button--ghost c-button--small"
                   v-on:click="addValue()"/>
      </div>
    </div>
  </div>
</template>

<script>
import clone from 'lodash/clone';

export default {
  name: 'categoryAwards',
  props: ['value', 'namePrefix'],
  data: function() {
    return {
      list: [],
      spareLabel: null,
      spareValue: 0,
    };
  },
  created: function() {
    this.list = clone(this.value);
  },
  watch: {
    list: {
      handler: function() {
        this.$emit('input', this.list);
      },
      deep: true,
    },
  },
  methods: {
    addValue: function() {
      if (! this.spareLabel) {
        return;
      }

      this.list.push({
        label: this.spareLabel,
        value: this.spareValue ? this.spareValue : 0
      });

      this.spareLabel = undefined;
      this.spareValue = 0;
    },
    removeValue: function(index) {
      this.list.splice(index, 1);
    }
  }
}
</script>

<style scoped>

</style>
